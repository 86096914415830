import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { ThemesService } from './themes/themes.service';
import { ESupportedThemes } from './themes/themes.types';
import { LanguageService } from './dynamic/language.service';
import { GlobalHelper, COPY, goToExternalLink } from './global.helper';
import { emptyLinkedInScrappedData, ILinkedInScrappedData } from './global.types';
import { ReCaptcha2Component } from 'ngx-captcha';

declare const chrome: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})

export class AppComponent implements OnInit {

  @ViewChild('captchaRef') captchaRef: ReCaptcha2Component;

  public siteKey: string = environment.SITE_KEY;
  currentDate: Date = new Date();
  loginForm: FormGroup;
  recaptcha: string = '';
  errMsg: string = '';
  previousTab: string = '';
  activeTab: string = 'contact';
  noProtocolLinkedInUrl: string = '';

  showBack: boolean = false;
  isChromeTab: boolean = false;
  showPassword: boolean = false;
  isLinkedInUrl: boolean = false;
  isLinkedCmpUrl: boolean = false;
  isCaptchaChecked: boolean = false;

  linkedInScrappedData: ILinkedInScrappedData = COPY(emptyLinkedInScrappedData);

  constructor(
    public ls: LanguageService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private themesService: ThemesService,
    public globalHelper: GlobalHelper,
  ) {
    this.checkIfChromeTab();
  }

  ngOnInit() {
    this.initialiseLoginForm();
    this.getProspectDetails();
    if (this.authService.loginUserDetails) {
      this.globalHelper.getSettings('onload');
    }
    this.globalHelper.postMessageToParent(this.globalHelper.pluginSettings);
  }

  initialiseLoginForm() {
    this.loginForm = this.formBuilder.group({
      emailId: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      password: ['', [Validators.required]],
      remember: [false],
    });
  }

  async onLoginSubmit() {
    this.errMsg = '';
    if (this.loginForm.invalid) {
      this.errMsg = this.ls.data.toasts.pleaseEnterValidEmail;
      return;
    }
    if (!this.isCaptchaChecked && !environment.QA) {
      this.errMsg = 'Please complete CAPTCHA verification';
      return;
    }
    const {
      emailId,
      password,
    } = this.loginForm.value;
    const browser = this.globalHelper.getBrowserNameAndVersion().name;
    const requestData = {
      email: emailId,
      password,
      remember: false,
      browser,
    };
    try {
      await this.authService.login(requestData, this.recaptcha);
      this.globalHelper.getSettings('login');
      this.resetLoginForm();
    } catch (error) {
      this.handleLoginError(error);
    } finally {
      this.finalizeLogin();
    }
  }

  resetLoginForm() {
    this.loginForm.reset({
      emailId: '',
      password: '',
      remember: false,
    });
  }

  handleLoginError(error: any) {
    console.error(error);
    this.errMsg = error?.error?.langCode
      ? this.ls.takeErrorMsg(error.error.langCode)
      : this.ls.data.toasts.somethingWentWrong;

    setTimeout(() => (this.errMsg = ''), 3000);
  }

  finalizeLogin() {
    this.recaptcha = '';
    this.isCaptchaChecked = false;
    this.captchaRef?.reloadCaptcha();
  }

  promiscuousMode() {
    if (this.linkedInScrappedData.urlType === 'other') {
      return;
    }
    this.authService.promiscuousMode(this.linkedInScrappedData)
      .then();
  }

  getProspectDetails() {
    window.addEventListener('message', async(event) => {
      if (event.data?.type === 'messageFromContentScript') {
        const {
          urlType,
          contact,
          company,
          linkedinHTML,
          tabUrl,
        } = event.data;
        if (urlType === 'contact') {
          this.linkedInScrappedData.contact = contact;
        }
        if (urlType === 'company') {
          this.linkedInScrappedData.company = company;
        }
        Object.assign(this.linkedInScrappedData, {
          linkedinHTML,
          tabUrl,
          urlType,
        });
      }
      await this.setNavigation();
    });
  }

  async setNavigation() {
    const linkedInUrl: any = await this.globalHelper.getActiveTabLinkedinUrl(this.linkedInScrappedData.tabUrl);
    this.noProtocolLinkedInUrl = linkedInUrl.finalLinkedInUrl;
    this.isLinkedInUrl = linkedInUrl.isLinkedInUrl || !linkedInUrl.finalLinkedInUrl;
    this.isLinkedCmpUrl = this.linkedInScrappedData.tabUrl.includes('https://www.linkedin.com/company/');
    this.activeTab = this.isLinkedInUrl ? (this.isLinkedCmpUrl ? 'company' : 'contact') : 'company';
    this.promiscuousMode();
  }

  checkIfChromeTab() {
    this.isChromeTab = !!(chrome && chrome.runtime && chrome.runtime.id);
  }

  searchProspects(clicked: string) {
    this.globalHelper.pluginSettings.clicked = clicked;
    this.globalHelper.postMessageToParent(this.globalHelper.pluginSettings);
  }

  logout() {
    this.themesService.defineThemes('blue-theme' as ESupportedThemes);
    const browserName = this.globalHelper.getBrowserNameAndVersion().name || 'other';
    const body = { browser: browserName };
    this.authService.logout(body)
      .subscribe({
        next: () => {
          this.isCaptchaChecked = false;
        },
        error: (error) => {
          console.error('Logout error:', error);
        },
      });
  }

  triggerParentBodyClick() {
    window.close();
  }

  toggleFill(item: string, isInternalLink: boolean) {
    this.showBack = false;
    this.globalHelper.isExpanded.open = false;
    this.previousTab = this.activeTab;
    this.activeTab = this.activeTab !== item ? item : this.activeTab;
    if (item === 'search') {
      goToExternalLink(`${environment.PRE_URL}/auth/login?loaded=1&returnUrl=search`, true);
      setTimeout(() => {
        this.toggleFill(this.isLinkedCmpUrl ? 'company' : 'contact', false);
      }, 500);
    }
    if (item && isInternalLink) {
      this.showBack = true;
    }
  }

  returnBack() {
    this.toggleFill(this.previousTab, false);
  }

  onCaptchaPass(token: any) {
    this.recaptcha = token;
    this.isCaptchaChecked = true;
  }

  onCaptchaFail() {
    this.isCaptchaChecked = false;
    this.captchaRef?.reloadCaptcha();
  }

  minimizeExt(action: string) {
    this.globalHelper.pluginSettings.minimize = action;
    this.globalHelper.postMessageToParent(this.globalHelper.pluginSettings);
  }

  readonly environment: any = environment;

  protected readonly goToExternalLink = goToExternalLink;
}
