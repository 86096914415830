import { Component, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CommonService } from 'src/app/services/common.service';
import { GlobalHelper, goToExternalLink } from '../../global.helper';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/dynamic/language.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.less', '../contact/contact.component.less'],
})
export class EmployeeComponent implements OnInit {

  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;

  openFilter: boolean = false;
  isFilterApplied: boolean = false;

  filtersDept: string[] = [];
  filtersSeniority: string[] = [];
  selectedFiltersDept: string[] = [];
  selectedFiltersSeniority: string[] = [];

  filterDept: any;
  contactData: any;
  filterSeniority: any;

  isCntListAdded: boolean[] = new Array(10).fill(false);
  searchText: string = '';
  selectedFilterOption: string = '';

  timeout: number = 0;

  constructor(
    public commonService: CommonService,
    public globalHelper: GlobalHelper,
    public ls: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.initMethods();
  }

  initMethods() {
    this.globalHelper.getEmployeeList(0, 25, this.searchText, false)
      .then();
    this.getDepartmentSeniority();
    this.globalHelper.getDefaultCntList();
    this.globalHelper.getDefaultList();
  }

  getDepartmentSeniority() {
    this.commonService.getDepartmentSeniority()
      .then((data: any) => {
        if (data && data.seniority && data.department) {
          this.filtersSeniority = data.seniority.map((item: any) => item.name);
          this.filtersDept = data.department.map((item: any) => item.name);
        }
      })
      .catch((_error: any) => {
        console.error('_error', _error);
      });
  }

  searchEmployee() {
    this.globalHelper.employeeData.cndDataLoader = true;
    this.globalHelper.getEmployeeList(0, 25, this.searchText, false)
      .then();
  }

  triggerSearch() {
    clearTimeout(this.timeout);
    this.timeout = Number(setTimeout(() => {
      this.searchEmployee();
    }, 1000));
  }

  openFilters() {
    this.openFilter = !this.openFilter;
  }

  selectedFilters(selected: string) {
    this.selectedFilterOption = selected;
    this.isFilterApplied = true;
    this.openFilter = false;
  }

  addFilters(filter: string) {
    if (this.selectedFilterOption === 'Department') {
      if (this.selectedFiltersDept.includes(filter)) {
        this.selectedFiltersDept = this.selectedFiltersDept.filter(item => item !== filter);
      } else {
        this.selectedFiltersDept.push(filter);
      }
    }
    if (this.selectedFilterOption === 'Seniority') {
      if (this.selectedFiltersSeniority.includes(filter)) {
        this.selectedFiltersSeniority = this.selectedFiltersSeniority.filter(item => item !== filter);
      } else {
        this.selectedFiltersSeniority.push(filter);
      }
    }
    this.filterDept = this.selectedFiltersDept;
    this.filterSeniority = this.selectedFiltersSeniority;
    this.globalHelper.getEmployeeList(0, 25, this.searchText, this.filterDept, this.filterSeniority)
      .then();

  }

  clearAll() {
    this.searchText = '';
    this.selectedFilterOption = '';
    this.isFilterApplied = false;
    this.filterDept = [];
    this.filterSeniority = [];
    this.selectedFiltersDept = [];
    this.selectedFiltersSeniority = [];
    this.globalHelper.getEmployeeList(0, 25, this.searchText, false)
      .then();
  }

  goToProspect() {
    goToExternalLink(`${environment.PRE_URL}/auth/login?&cmpName=${this.globalHelper.pDetails.company_name}`, true);
  }

  viewContact(contactId: string, toggleCollapse: boolean = false): void {
    const handleData = (contactData: any) => {
      const previousContactId = this.contactData?.contactId;
      this.contactData = contactData;
      if (previousContactId && previousContactId !== contactId) {
        const previousContact = this.globalHelper.employeeData.cntData.find((contact: any) => contact.contactId === previousContactId);
        if (previousContact) {
          previousContact.isContactCollapse = true;
          previousContact.alreadyView = true;
        }
      }
      this.globalHelper.employeeData.cntData.forEach((contact: any) => {
        contact.isContactCollapse = toggleCollapse ? contact.contactId === contactId ? !contact.isContactCollapse : false : contact.contactId === contactId;
        contact.alreadyView = contact.contactId === contactId || contact.alreadyView;
      });
    };
    const handleError = (error: any) => {
      this.globalHelper.toastMessages(this.ls.takeErrorMsg(error?.error?.langCode), 'error');
    };
    this.commonService.contactView(contactId)
      .then(handleData)
      .catch(handleError);
  }

  addToCntList(contactId: string, i: number, companyId: string): void {
    this.globalHelper.getCntList(contactId);
    const { defaultSelectedCntList } = this.globalHelper.defaultList;
    const { askWhereToSave } = this.globalHelper.pluginSettings;
    this.isCntListAdded.fill(false);
    this.isCntListAdded[i] = true;
    if (defaultSelectedCntList !== undefined) {
      if (askWhereToSave) {
        this.globalHelper.selectedListType.selectedCntList = [defaultSelectedCntList];
      } else {
        this.saveContactList([defaultSelectedCntList], i, contactId, companyId);
        this.globalHelper.getEmployeeList(0, 5)
          .then();
      }
    }
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  saveContactList(saveCntList: any, i: number, contactId: string, companyId?: string) {

    if (this.globalHelper.defaultList.defaultSelectedCntList !== '') {
      this.isCntListAdded[i] = false;
    } else {
      this.isCntListAdded[i] = !this.isCntListAdded[i];
      if (this.cntListSelect) {
        this.cntListSelect.clearModel();
      }
    }

    if (saveCntList.length) {
      let request: any = {
        'indexIds': [contactId],
        'moduleType': 'mylist',
        'type': 'contact',
        'listName': saveCntList,
      };
      this.commonService.saveCntList(request)
        .then(() => {
          this.globalHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          if (companyId) {
            this.globalHelper.getEmployeeList(0, 25, this.searchText, false)
              .then();
          }
        })
        .catch((_error: any) => {
          this.globalHelper.toastMessages(this.ls.data.contact.contactAlreadyExist, 'error');
        });
    }
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  onListCancel(i: number) {
    this.isCntListAdded[i] = !this.isCntListAdded[i];
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  protected readonly goToExternalLink = goToExternalLink;
}
