import { ILang } from './lang.pack';
import * as Languages from './languages';

export class LanguageService {

  public data: ILang = Languages.LEnglish;

  constructor() {
  }

  public takeErrorMsg(errorName: string) {
    // @ts-ignore
    const res: string | undefined = this.data.errors[errorName];
    return res ?? this.data.errors.placeholder;
  }

}
