import { ILang } from '../lang.pack';

/* eslint-disable max-len */
export const LEnglish: ILang = {
  global: {
    misc: {
      copyright: 'Copyright @Predictiv',
      predictiv: 'Predictiv',
      comingSoon: 'Coming soon...',
      loading: 'Loading...',
      notAvailable: 'Not Available',
      viewLess: '...View less',
      viewMore: '...View more',
      upgrade: 'Upgrade',
      feedbackLabel: 'Anything else we should know about these issues?',
      feedbackPlaceholder: 'Write your comment here.',
    },
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
      copy: 'Copy',
      correct: 'Correct',
      incorrect: 'Incorrect',
      copied: 'Copied',
      close: 'Close',
      no: 'No',
      yes: 'Yes',
      edit: 'Edit',
      submitFeedback: 'Submit Feedback',
    },
    toolTip: {
      editProfile: 'Edit Profile',
      thisCostOneCredit: 'This costs one credit',
    },
    modal: {
      feedbackHeader: 'Oops, Got a Data Oopsie?',
      feedbackDesc: 'Looks like we hit a little bump with the data we showed you. Keen to get it sorted and we\'d really value your two cents on what\'s amiss. Got a sec to drop us your thoughts? It\'ll be a big help in clearing this up and steering clear of a repeat.',
    },
  },
  actions: {
    showDetails: 'Show Details',
    hide: 'Hide',
  },
  footer: {
    copyright: 'Copyright @Predictiv',
    privacyPolicy: 'Privacy Policy',
  },
  company: {
    copiedSuccess: 'Copied successfully',
    savedToList: 'Saved to list',
    unableToSave: 'Unable to save',
    fileNameAlreadyExist: 'File name already exists',
    companyAlreadyExist: 'Company already added to default list',
    subscribeIsExpire: 'Subscription is expired.',
    noActiveSubPresent: 'No active subscription present.',
    yourViewCreditExceed: 'Your view limit is exceeded.',
    createNewList: 'Create new list',
    moreCompanyDetails: 'More Company Details',
    noCompanyDataFoundYetWe: 'No company data found yet. We are working on a data scavenger hunt for more info.',
    workEmail: 'Work Email',
    phoneOffice: 'Phone Office',
    directPhone: 'Direct Phone',
    keyEmployees: 'Key Employees',
    showEmployees: 'Show Employees',
    specialities: 'Specialities',
    industry: 'Industry',
    annualRevenueRange: 'Annual Revenue Range',
    employeeSize: 'Employee Size',
    headquarterLocation: 'Headquarters Location',
    foundIn: 'Founded In',
    companyInfo: 'Company Info',
    hqPrefix: 'HQ - ',
    oneViewIsRequire: '1 View is required',
    placeholder: {
      searchCreateNewList: 'Search/Create new lists',
    },
    toolTip: {
      addToList: 'Add to list',
    },
  },
  contact: {
    contactAlreadyExist: 'Contact already added to default list',
    stayTunedDataComingSoon: 'Stay tuned, data coming soon!',
    officeEmail: 'Office Email',
    showContact: 'Show Contact',
    officePhone: 'Office Phone',
    moreContactDetails: 'More Contact Details',
    about: 'About',
  },
  dashboard: {
    linkedInProspector: 'Linkedin Prospector',
    linkedIn: 'LinkedIn',
    predictivExtension: 'PREDICTIV EXTENSION',
    extension: 'Extension',
    dashboard: 'Dashboard',
    predictivPlatform: 'PREDICTIV PLATFORM',
    goToPredictiv: 'Go to Predictiv',
    wayToWorkWithPredictiv: 'Way to work with Predictiv',
    activity: 'Activity',
    search: 'Search',
    employees: 'Employees',
    company: 'Company',
    contact: 'Contact',
    exploreCompanyProfiles: 'Explore company profiles',
    companyProspecting: 'Company Prospecting',
    exploreLinkedInProfile: 'Explore LinkedIn profile',
    contactProspecting: 'Contact Prospecting',
    discoverLimitLess: 'Discover limitless connections today! Click on the contact/company prospecting to get the most out of Predictiv.',
    login: 'Login',
    password: 'Password*',
    email: 'Email*',
    welcome: 'Welcome!',
  },
  employee: {
    noDataFound: 'No data found',
    viewMore: 'View More',
    clearAll: 'Clear all',
    department: 'Department',
    seniority: 'Seniority',
    startTypingToSearch: 'Start typing to search',
  },
  activities: {
    activity: 'Activity',
    recent: 'Recent',
    company: 'Company',
    contact: 'Contact',
  },
  settings: {
    areYouSureYouWantSign: 'Are you sure you want to sign out?',
    signOut: 'Sign Out',
    askWhereToSaveList: 'Ask where to save list',
    defaultList: 'Default List',
    predictivEveryWhere: 'Predictiv Everywhere',
    autoOpen: 'Auto Open',
    pluginPosition: 'Plugin Position',
    extensionPreferences: 'Extension Preferences',
    predictivPlatform: 'Predictiv Platform',
    platform: 'Platform',
    credits: 'Credits',
    themes: 'Themes',
  },
  toasts: {
    somethingWentWrong: 'Something went wrong, please try later',
    pleaseEnterValidEmail: 'Please enter a valid email or password',
    unableToComplete: 'Error: Unable to complete request.',
  },
  errors: {
    placeholder: 'Unpredicted error, please contact administrator!',
    AUTH008: 'Email / Password is incorrect.',
    AUTH006: `Just a heads-up, we allow up to three free accounts only per corporate domain.`,
    AUTH007: 'Email / Password is incorrect.',
    AUTH21: 'Error occurred at ReCaptcha validation',
    AUTH009: 'Error occurred while inserting refreshToken',
    AUTH030: 'Your subscription is expired.',
    SIE: 'Subscription is expired.',
    NOACTIVESUBPRESENT: 'No active subscription present',
    YOURVIEWLIMITEXEED: 'Your view limit is exceeded',
    YSHECSRI: 'Your subscription has expired, contact support@predictivdata.com to renew it.',
    YOUR001: 'Your view limit is exceeded',
    YOUR0022222: 'Your view limit is exceeded',
  },
  success: {
    placeholder: 'Unpredicted error, please contact administrator!',
  },
};
