import { Component, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CommonService } from 'src/app/services/common.service';
import { GlobalHelper, goToExternalLink } from '../../global.helper';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/dynamic/language.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.less', '../contact/contact.component.less', '../employee/employee.component.less'],
})
export class ActivityComponent implements OnInit {

  @ViewChild('cntListSelect') cntListSelect!: NgSelectComponent;
  @ViewChild('cmpListSelect') cmpListSelect!: NgSelectComponent;
  @ViewChild('cntDefaultListSelect') cntDefaultListSelect!: NgSelectComponent;
  @ViewChild('cmpDefaultListSelect') cmpDefaultListSelect!: NgSelectComponent;

  activeItem: any;
  contactData: any;

  isCntListAdded: boolean[] = new Array(10).fill(false);
  isCmpListAdded: boolean[] = new Array(10).fill(false);

  limit: number = 0;
  offset: number = 5;

  items = [
    {
      name: this.ls.data.activities.recent,
      isActive: false,
      imgPath: 'assets/images/svg/recent-tab.svg',
    },
    {
      name: this.ls.data.activities.contact,
      isActive: false,
      imgPath: 'assets/images/svg/contact-tab.svg',
    },
    {
      name: this.ls.data.activities.company,
      isActive: false,
      imgPath: 'assets/images/svg/company-tab.svg',
    },
  ];

  constructor(
    public commonService: CommonService,
    public globalHelper: GlobalHelper,
    public ls: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.onItemClick(this.items[0]);
    this.initMethods();
  }

  initMethods() {
    this.globalHelper.getDefaultCntList();
    this.globalHelper.getDefaultCmpList();
    this.globalHelper.getDefaultList();
  }

  onItemClick(item: any) {
    this.items.forEach(i => i.isActive = false);
    item.isActive = true;
    this.activeItem = item;
    this.isCntListAdded.fill(false);
    this.isCmpListAdded.fill(false);
    this.resetTab();
  }

  goToDetails(cntId: string, type: string) {
    if (cntId && this.globalHelper.pDetails.isSubExpire == '') {
      goToExternalLink(`${environment.PRE_URL}auth/login?loaded=1&returnUrl=/search/${type}/${cntId}`, true);
    } else {
      this.globalHelper.toastMessages(this.ls.data.errors.SIE, 'error');
    }
  }

  handleContactView(contactId: any, toggleCollapse: boolean = false) {
    const handleData = (contactData: any) => {
      this.contactData = contactData;
      this.globalHelper.listData.data.forEach((contact: any) => {
        contact.isContactCollapse = toggleCollapse ? contact.id === contactId ? !contact.isContactCollapse : false : contact.id === contactId;
        contact.alreadyView = contact.id === contactId ? true : contact.alreadyView;
      });
    };

    const handleError = (_error: any) => {
      this.globalHelper.toastMessages(this.ls.takeErrorMsg(_error?.error?.langCode), 'error');
    };

    this.commonService.contactView(contactId)
      .then(data => {
        handleData(data); // Call your success handler with the resolved data
      })
      .catch(error => {
        handleError(error); // Call your error handler with the caught error
      });
  }

  onDefaultListChange(event: any, type: 'contact' | 'company') {
    // creating new list
    if (event?.value) {
      const typeList: 'contacts' | 'companies' = type === 'contact' ? 'contacts' : 'companies';
      this.commonService.createList({ 'moduleType': 'mylist' }, typeList, type, event.value)
        .then();
    }

    // setting default list
    const request: any = {};
    const key: 'defaultListContactName' | 'defaultListCompanyName' = type === 'contact' ?
      'defaultListContactName' : 'defaultListCompanyName';
    request[key] = event?.label || '';

    this.commonService.saveSetting(request)
      .then((result: any) => {
        const defaultListName = type === 'contact' ? result?.defaultListContactName : result?.defaultListCompanyName;
        const targetList: 'defaultSelectedCntList' | 'defaultSelectedCmpList' = type === 'contact' ?
          'defaultSelectedCntList' : 'defaultSelectedCmpList';
        this.globalHelper.defaultList[targetList] = defaultListName || undefined;
      })
      .catch((_error: any) => {
        console.error('_error:', _error);
      });
  }

  getCntList(contactId: any, i: any) {
    this.globalHelper.getCntList(contactId);
    if (this.globalHelper.defaultList.defaultSelectedCntList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCntListAdded.fill(false);
        this.isCmpListAdded.fill(false);
        this.isCntListAdded[i] = true;
        this.globalHelper.selectedListType.selectedCntList = this.globalHelper.defaultList.defaultSelectedCntList ? [this.globalHelper.defaultList.defaultSelectedCntList] : [];
      } else {
        this.saveContactList([this.globalHelper.defaultList.defaultSelectedCntList], i, contactId);
        if (this.cntListSelect) {
          this.cntListSelect.clearModel();
        }
        this.resetTab();
      }
    } else {
      this.isCntListAdded.fill(false);
      this.isCmpListAdded.fill(false);
      this.isCntListAdded[i] = true;
      this.globalHelper.getCntList(contactId);
      if (this.cntListSelect) {
        this.cntListSelect.clearModel();
      }
    }
  }

  getCmpList(companyId: any, i: any) {
    this.globalHelper.getCmpList(companyId);
    if (this.globalHelper.defaultList.defaultSelectedCmpList !== undefined) {
      if (this.globalHelper.pluginSettings.askWhereToSave) {
        this.isCmpListAdded.fill(false);
        this.isCntListAdded.fill(false);
        this.isCmpListAdded[i] = true;
        this.globalHelper.selectedListType.selectedCmpList = this.globalHelper.defaultList.defaultSelectedCmpList ? [this.globalHelper.defaultList.defaultSelectedCmpList] : [];
      } else {
        this.saveCompanyList([this.globalHelper.defaultList.defaultSelectedCmpList], i, companyId);
        if (this.cmpListSelect) {
          this.cmpListSelect.clearModel();
        }
        this.resetTab();
      }
    } else {
      this.isCmpListAdded.fill(false);
      this.isCntListAdded.fill(false);
      this.isCmpListAdded[i] = true;
      this.globalHelper.getCmpList(companyId);
      if (this.cmpListSelect) {
        this.cmpListSelect.clearModel();
      }
    }
  }

  saveContactList(saveCntList: any, i: any, contactId: any) {
    if (this.globalHelper.defaultList.defaultSelectedCntList !== '') {
      this.isCntListAdded[i] = false;
    } else {
      this.isCntListAdded[i] = !this.isCntListAdded[i];
      if (this.cntListSelect) {
        this.cntListSelect.clearModel();
      }
    }
    if (saveCntList.length) {
      let postBody = {
        'indexIds': [contactId],
        'moduleType': 'mylist',
        'type': 'contact',
        'listName': saveCntList,
      };
      this.commonService.saveCntList(postBody)
        .then(() => {
          this.globalHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          if (this.globalHelper.defaultList.defaultSelectedCntList !== '') {
            this.onListCancel('contact');
            this.resetTab();
          }
        })
        .catch((_error: any) => {
          this.globalHelper.toastMessages(this.ls.data.contact.contactAlreadyExist, 'error');
          if (this.globalHelper.defaultList.defaultSelectedCntList !== '') {
            this.onListCancel('contact');
          }
        });
    }
    if (this.cntListSelect) {
      this.cntListSelect.clearModel();
    }
  }

  saveCompanyList(saveCmpList: any, i: any, companyId: any) {
    if (this.globalHelper.defaultList.defaultSelectedCmpList !== '') {
      this.isCmpListAdded[i] = false;
    } else {
      this.isCmpListAdded[i] = !this.isCmpListAdded[i];
      if (this.cmpListSelect) {
        this.cmpListSelect.clearModel();
      }
    }
    if (saveCmpList.length) {
      let postBody = {
        'indexIds': [companyId],
        'moduleType': 'mylist',
        'type': 'account',
        'listName': saveCmpList,
      };
      this.commonService.saveCmpList(postBody)
        .then(() => {
          this.globalHelper.toastMessages(this.ls.data.company.savedToList, 'success');
          if (this.globalHelper.defaultList.defaultSelectedCmpList !== '') {
            this.onListCancel('company');
            this.resetTab();
          }
        })
        .catch((_error: any) => {
          this.globalHelper.toastMessages(this.ls.data.company.companyAlreadyExist, 'error');
          if (this.globalHelper.defaultList.defaultSelectedCmpList !== '') {
            this.onListCancel('company');
          }
        });
    }
    if (this.cmpListSelect) {
      this.cmpListSelect.clearModel();
    }
  }

  onListCancel(type: 'contact' | 'company', i?: any) {
    if (type === 'contact') {
      this.isCntListAdded[i] = !this.isCntListAdded[i];
      if (this.cntListSelect) {
        this.cntListSelect.clearModel();
      }
    } else {
      this.isCmpListAdded[i] = !this.isCmpListAdded[i];
      if (this.cmpListSelect) {
        this.cmpListSelect.clearModel();
      }
    }
  }

  onPageScroll(event: any, tolerance: number = 1) {
    if (!this.globalHelper.listData.cndDataLoader && this.globalHelper.listData.enabledScroll) {
      const element: HTMLElement = event.target as HTMLElement;
      if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= tolerance) {
        this.limit += this.offset;
        this.globalHelper.getListData(this.activeItem.name, this.limit, this.offset)
          .then();
      }
    }
  }

  resetTab() {
    this.limit = 0;
    this.offset = 5;
    this.globalHelper.listData = {
      data: [],
      cntData: [],
      cmpData: [],
      enabledScroll: true,
      cndDataLoader: true,
    };
    this.globalHelper.getListData(this.activeItem.name, 0, 5)
      .then();
  }

  protected readonly goToExternalLink = goToExternalLink;
  protected readonly environment: any = environment;
}
